<template>
  <div>
    <v-data-table dense disable-pagination hide-default-footer :loading="loadingTable" :headers="headers"
      :items="dialys" class="elevation-1">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Itens Lançados</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" icon small outlined large class="ml-2" color="primary"
                @click="sendExportPdf()">
                <v-icon>{{ icons.mdiPrinter }}</v-icon>
              </v-btn>
            </template>
            <span>Imprimir</span>
          </v-tooltip>
          <v-badge :content="bagFilter" :value="bagFilter" bordered color="error" overlap>
            <v-btn @click="dialogFilter = true" class="ml-2" color="primary" outlined rounded>
              <v-icon>{{ icons.mdiFilter }}</v-icon>Filtros
            </v-btn>
          </v-badge>
          <v-spacer></v-spacer>
          <span>Periodo:<b style="color:black">{{ formatDate(filter.date_start) }}</b></span>
        </v-toolbar>
      </template>
      <template v-slot:item.value="{ item }">
        {{ formatMoney(item.value) }}
      </template>
      <template v-slot:item.sale_price="{ item }">
        {{ formatMoney(item.sale_price) }}
      </template>
      <template v-slot:item.quantity="{ item }">
        {{ formatMoney(item.quantity) }}
      </template>
      <template v-slot:item.created_at="{ item }">
        {{ formatDateTime(item.created_at) }}
      </template>
      <template v-slot:item.total="{ item }">
        {{ formatMoney(parseFloat(item.quantity) * parseFloat(item.sale_price)) }}
      </template>
      <template v-slot:foot>
        <tr style="height:44px; background:#fafafa">
          <td colspan="6" class="text-right" style="border-top:solid 1px #e4e3e6;padding-left:16px;padding-right:16px">
            TOTAL:</td>
          <td class="text-right" style="border-top:solid 1px #e4e3e6 ;padding-left:16px;padding-right:16px">
            <b>{{ formatMoney(calcTotal()) }}</b>
          </td>
        </tr>
      </template>

      <template v-slot:no-data>
        <NoDataTable></NoDataTable>
      </template>
      <template v-slot:loading>
        Carregando...
      </template>

    </v-data-table>

    <!-- DIALOG FILTER -->
    <v-dialog v-model="dialogFilter" width="700px">
      <v-card>
        <BarTitleDialog title="Filtros" @close="dialogFilter = false" />
        <v-card-text>
          <v-row>
            <v-col>
              <v-card outlined>
                <v-card-text>
                  <v-row no-gutters>
                    <v-col>
                      <v-text-field v-model="filter.date_start" :error-messages="error.date_start"
                        @change="error.date_start = null" outlined label="Data inicial" type="date"></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col class="text-left">
              <v-btn text color="error" x-large @click="clearFilter()">limpar filtros</v-btn>
            </v-col>

            <v-col class="text-right">
              <v-btn text color="secondary" x-large @click="dialogFilter = false">FECHAR</v-btn>
              <v-btn :loading="loadingFilter" :disabled="loadingFilter" @click="confirmFilter()" color="primary"
                class="ml-2" x-large>aplicar</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <MsgDelete v-model="dialogDelete" @delete-yes="deleteItemConfirm()" />
  </div>
</template>

<script>
import MsgDelete from '@/components/MsgDelete.vue'
import NoDataTable from '@/components/NoDataTable.vue'
import { mdiClose, mdiTools, mdiFileExcelOutline, mdiFilter, mdiPrinter, mdiFilePdf, mdiFilePdfOutline } from '@mdi/js'
import { mapActions, mapGetters } from 'vuex'
import BarTitleDialog from '@/components/BarTitleDialog.vue'
import axios from '@axios'
import { formatDate } from '@/@core/utils/filter'

export default {
  components: {
    MsgDelete,
    NoDataTable,
    BarTitleDialog,
  },
  data: () => ({
    // DIALOG FILTER
    dialogFilter: false,
    filter: {},
    loadingFilter: false,
    loadingTable: false,
    totalStagesPaginate: 0,
    numberOfPagesPaginate: 0,
    currentPagePaginate: 0,
    dialogDelete: false,
    dialog: false,
    loading: false,
    dialys: [],
    icons: {
      mdiClose,
      mdiTools,
      mdiFileExcelOutline,
      mdiFilter,
      mdiPrinter,
      mdiFilePdf,
      mdiFilePdfOutline,
    },
    headers: [
      {
        text: 'Quarto',
        align: 'center',
        value: 'accommodation.room.description',
      },
      {
        text: 'Data/Hora',
        align: 'start',
        value: 'created_at',
        sortable: false,
      },
      {
        text: 'Descrição',
        align: 'center',
        value: 'description',
      },
      {
        text: 'Tipo',
        align: 'center',
        value: 'type',
      },
      {
        text: 'Quantidade',
        align: 'center',
        value: 'quantity',
      },
      {
        text: 'Valor',
        align: 'end',
        value: 'sale_price',
        sortable: false,
      },
      {
        text: 'Total',
        align: 'end',
        value: 'total',
        sortable: false,
      },
    ],
    error: {
      date_start: null,
      date_end: null,
    },
  }),

  computed: {
    bagFilter() {
      return Object.keys(this.filter).length
    },
  },

  watch: {

  },

  created() {

    this.periodInitial()

    this.initialize()
  },

  methods: {
    ...mapActions('daily_report', ['dailyItensReport', 'exportPdfItens']),

    initialize() {
      this.loadingTable = true
      axios
        .post('debug/itens_report', this.filter)
        .then((response) => {
          this.dialys = response.data
          this.loadingTable = false
        })
        .catch((error) => {
          this.loadingTable = false
          console.log(error)
        }).finally(() => {
          this.loadingTable = false
          this.loadingFilter = false
          this.dialogFilter = false
        })

    },
    periodInitial() {
      let dateToday = new Date();
      dateToday.setDate(dateToday.getDate() - 1);
      dateToday.setHours(dateToday.getHours() - 3);
      this.filter.date_start = dateToday.toISOString().split('T')[0]
    },
    confirmFilter() {
      //validar filtro periodo
      if (!this.filter.date_start) {
        this.error.date_start = 'Campo obrigatório'
        return false
      }
      this.loadingFilter = true
      this.initialize()
    },
    clearFilter() {
      this.filter = {}
    },
    formatMoney(value) {
      if (value != null) {
        return value.toLocaleString('pt-br', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      }
    },
    formatDate(dateTime) {
      if (!dateTime) return null

      const [date, time] = dateTime.split('T')
      const [year, month, day] = date.split('-')

      return `${day}/${month}/${year} `
    },
    calcTotal() {
      let total = 0
      this.dialys.forEach((item) => {
        total += parseFloat(item.sale_price) * parseFloat(item.quantity)
      })
      return total
    },
    sendExportPdf() {
      this.exportPdfItens(this.filter)
        .then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]))
          var fileLink = document.createElement('a')

          fileLink.href = fileURL
          fileLink.setAttribute('download', 'export.pdf')
          document.body.appendChild(fileLink)
          fileLink.click()
        })
        .finally()
    },
    formatDateTime(dateTime) {
      if (!dateTime) return null

      let date = new Date(dateTime);
      date.setHours(date.getHours() - 3);
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear();

      const hour = date.getHours().toString().padStart(2, '0');
      const minute = date.getMinutes().toString().padStart(2, '0');

      return `${day}/${month}/${year} ` + ' às ' + `${hour}:${minute}`
    },
  },
}
</script>
